.button--sacnite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  box-shadow: inset 0 0 0 35px #333;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  transition: box-shadow 0.3s, transform 0.3s;
}

.button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.button--round-l {
  border-radius: 40px;
}

.button {
  border-radius: 0;
  float: none;
  margin: 0 auto;
  margin-top: 0px;
  padding: 5px 20px;
  display: inline-block;
  font-weight: 400;
  max-width: 400px;
}

.button.button--sacnite {
  width: 60px;
  height: 60px;
  min-width: 0;
  padding: 0;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.button--sacnite:hover {
  color: #333;
}

.button--sacnite:hover::before {
  box-shadow: inset 0 0 0 2px #333;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}