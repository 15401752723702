@import "base";
@import "button";
@import "type";

$shadow: #533d4a;

ul.icons a:hover {
    background: rgba(0, 0, 0, 0.025);
}

ul.icons li {
    display: inline-block;
    padding-left: 0.75em;
}

ul.icons a {
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
    -ms-transition: background-color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    display: inline-block;
    width: 2.75em;
    height: 2.75em;
    line-height: 2.8em;
    text-align: center;
    border: 0;
    box-shadow: none;
    padding:0;
    border-radius: 100%;
    color: #aaa;
}

.icons i {
    font-size: 30px;
    line-height:60px;
}

.icons {
    padding: 0;
    font-size: 1em;
    margin-bottom:20px;
    text-align: right;
}

@media screen and (max-width: 768px) {
    .icons {
        text-align: center;
    }
}

.header-right p {
    margin-bottom: 3px;
}

.header-right a{
    color:inherit;
    font-weight:500;
}

@media screen and (max-width: 768px) {
    .header-container div{
        text-align: center;
    }

    .header-right p {
        text-align: center;
    }

    .header-left {
        margin-top: 0px;
    }
}

.profile-img {
    width: 200px;
    height: 200px;
    margin: 0 auto 20px;
    display: block;
    border-radius: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: repeat, no-repeat;
    -moz-box-shadow: inset 0 0 20px #636161;
    -webkit-box-shadow: inset 0 0 20px #636161;
    box-shadow: inset 0 0 20px #636161;
}

.into-text-1 {
    margin-top: 30px;
}

@media only screen and (max-width : 992px) {
    .into-text-1 {
        margin-top: 20px;
    }
}

.projects-container, .experience-container, .education-container {
    h3 {
        margin-bottom: 0;
    }
}

.projects-container {
    .products-container {
        display: flex;
        justify-content: space-around;
        .products-img {
            width: 30%;
            padding-top: 30%;
            background-size: cover;
            background-position: center;
        }
    }
}

.experience, .education, .project {
    margin-top: 3em;

    .left-column {
        text-align: left;

        p {
            margin-bottom: 3px;
            font-size: 1.6em;
            text-align: inherit;
            font-weight: 300;

            b {
                font-weight: 500;
            }
        }

        @media screen and (max-width: 768px) {
            text-align: center;

            p {
                text-align: center;
            }
        }

        a {
            color: inherit;
            font-style: italic;
            font-weight: 300;
        }

        .fa {
            font-size: 2em;
        }

        .project-link {
            font-size: 1.5em;
        }

        h4 {
            margin-bottom: 0.4em;
            font-weight: 500;

            @media print {
                margin-bottom: 0.2em;
            }

            a {
                font-weight: 500;
            }
        }
    }
}

.project:hover{
    cursor: pointer;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);

    box-shadow: inset 0 0 0 3px $shadow;
}
.footer-container {
    margin-top:100px;
}

.footer-container p{
    text-align: center;
    font-style: italic;
    font-weight: 300;
}

.footer-container a{
    color: inherit;
    font-style: italic;
    font-weight: 300;
}

.page-content {
    padding: 0;
}

.contact a {
    line-height: 50px;
    margin-left: 30px;
    color: #BBBBBB;
    -webkit-transition: color 0.4s ease;
    -moz-transition: color 0.4s ease;
    transition: color 0.4s ease;
}

.contact a:hover {
    color: #4C4C4C;
}

p.quote {
    font-size: 1.4em;
    font-style: italic;
    padding: 1em 2.5em;
    text-align: center;

    @media print {
        text-align: left;
        padding: 0em 1em;
        margin-top: 1em;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.overlay{
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    cursor: pointer;
    color: whitesmoke;
    text-shadow: $shadow 1px 1px, $shadow 2px 2px, $shadow 3px 3px, $shadow 4px 4px, $shadow 5px 5px, $shadow 6px 6px;
}