@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,500,500italic,400italic,300italic,100italic,700italic);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css);
button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #477dca;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  -webkit-transition: background-color 150ms ease;
  transition: background-color 150ms ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

button:hover,
button:focus, input[type="button"]:hover,
input[type="button"]:focus, input[type="reset"]:hover,
input[type="reset"]:focus, input[type="submit"]:hover,
input[type="submit"]:focus {
  background-color: #3964a2;
  color: #fff; }

button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled {
  cursor: not-allowed;
  opacity: 0.5; }

button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover {
  background-color: #477dca; }

fieldset {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin: 0 0 0.75em;
  padding: 1.5em; }

input,
label,
select {
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
  font-size: 1em; }

label {
  font-weight: 600;
  margin-bottom: 0.375em; }

label.required::after {
  content: "*"; }

label abbr {
  display: none; }

select {
  margin-bottom: 1.5em;
  max-width: 100%;
  width: auto; }

dl {
  margin-bottom: 0.75em; }

dl dt {
  font-weight: bold;
  margin-top: 0.75em; }

dl dd {
  margin: 0; }

table {
  border-collapse: collapse;
  -webkit-font-feature-settings: "kern", "liga", "tnum";
          font-feature-settings: "kern", "liga", "tnum";
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

html {
  box-sizing: border-box; }

*,
*::after,
*::before {
  box-sizing: inherit; }

.btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 18px 60px;
  display: inline-block;
  margin: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0;
  background: #DEDEDE;
  color: #4C4C4C; }

.btn:focus {
  background: #DEDEDE;
  color: #4C4C4C; }

.btn:hover {
  background: #CCCCCC;
  color: #4C4C4C; }

.btn:active {
  top: 2px; }

.btn.disabled {
  background: #EAEAEA;
  color: #9E9E9E;
  cursor: default;
  top: 0px; }

body {
  background: #ffffff;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased; }

.center-text {
  text-align: center; }

.container {
  margin-top: 30px; }
  @media print {
    .container {
      margin-top: 20px; } }

.header-contianer {
  margin-top: 50px; }

.header-right {
  text-align: right; }

.header-left {
  margin-top: 30px; }
  @media print {
    .header-left {
      margin-top: 0px; } }

.header-right p {
  font-size: 1.6em;
  text-align: right; }

@media print {
  a[href]:after {
    content: none !important; }
  img[src]:after {
    content: none !important; }
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-print-12 {
    width: 100%; }
  .no-print, .no-print * {
    display: none !important; } }

.button--sacnite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  box-shadow: inset 0 0 0 35px #333;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s; }

.button {
  float: left;
  min-width: 150px;
  max-width: 250px;
  display: block;
  margin: 1em;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale; }

.button--round-l {
  border-radius: 40px; }

.button {
  border-radius: 0;
  float: none;
  margin: 0 auto;
  margin-top: 0px;
  padding: 5px 20px;
  display: inline-block;
  font-weight: 400;
  max-width: 400px; }

.button.button--sacnite {
  width: 60px;
  height: 60px;
  min-width: 0;
  padding: 0;
  color: #fff;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }

.button--sacnite:hover {
  color: #333; }

.button--sacnite:hover::before {
  box-shadow: inset 0 0 0 2px #333;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

body {
  color: #333;
  font-family: Roboto, sans-serif;
  -webkit-font-feature-settings: "kern", "liga", "pnum";
          font-feature-settings: "kern", "liga", "pnum";
  font-size: 1em;
  line-height: 1.5; }
  @media print {
    body {
      font-size: 0.9em; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  font-size: 1em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #477dca;
  -webkit-transition: color 150ms ease;
  transition: color 150ms ease; }

a:active,
a:focus,
a:hover {
  color: #355e98; }

h1 {
  font-size: 4em;
  margin-bottom: 0.1em;
  font-weight: 500; }

h2 {
  font-weight: 300;
  font-size: 2em; }

h3 {
  font-weight: 300;
  font-size: 3em;
  text-align: center;
  border-bottom: dashed 2px #CCCCCC;
  padding-bottom: 10px;
  margin-bottom: 40px; }
  @media print {
    h3 {
      margin-bottom: 20px; } }

h4 {
  font-weight: 300;
  font-size: 2.5em;
  margin-bottom: 3px; }

p, ul {
  font-size: 1.6em;
  text-align: justify; }

a i {
  font-size: 1.6em; }

mark {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

ul.icons a:hover {
  background: rgba(0, 0, 0, 0.025); }

ul.icons li {
  display: inline-block;
  padding-left: 0.75em; }

ul.icons a {
  -webkit-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  display: inline-block;
  width: 2.75em;
  height: 2.75em;
  line-height: 2.8em;
  text-align: center;
  border: 0;
  box-shadow: none;
  padding: 0;
  border-radius: 100%;
  color: #aaa; }

.icons i {
  font-size: 30px;
  line-height: 60px; }

.icons {
  padding: 0;
  font-size: 1em;
  margin-bottom: 20px;
  text-align: right; }

@media screen and (max-width: 768px) {
  .icons {
    text-align: center; } }

.header-right p {
  margin-bottom: 3px; }

.header-right a {
  color: inherit;
  font-weight: 500; }

@media screen and (max-width: 768px) {
  .header-container div {
    text-align: center; }
  .header-right p {
    text-align: center; }
  .header-left {
    margin-top: 0px; } }

.profile-img {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  display: block;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: repeat, no-repeat;
  box-shadow: inset 0 0 20px #636161; }

.into-text-1 {
  margin-top: 30px; }

@media only screen and (max-width: 992px) {
  .into-text-1 {
    margin-top: 20px; } }

.projects-container h3, .experience-container h3, .education-container h3 {
  margin-bottom: 0; }

.projects-container .products-container {
  display: flex;
  justify-content: space-around; }
  .projects-container .products-container .products-img {
    width: 30%;
    padding-top: 30%;
    background-size: cover;
    background-position: center; }

.experience, .education, .project {
  margin-top: 3em; }
  .experience .left-column, .education .left-column, .project .left-column {
    text-align: left; }
    .experience .left-column p, .education .left-column p, .project .left-column p {
      margin-bottom: 3px;
      font-size: 1.6em;
      text-align: inherit;
      font-weight: 300; }
      .experience .left-column p b, .education .left-column p b, .project .left-column p b {
        font-weight: 500; }
    @media screen and (max-width: 768px) {
      .experience .left-column, .education .left-column, .project .left-column {
        text-align: center; }
        .experience .left-column p, .education .left-column p, .project .left-column p {
          text-align: center; } }
    .experience .left-column a, .education .left-column a, .project .left-column a {
      color: inherit;
      font-style: italic;
      font-weight: 300; }
    .experience .left-column .fa, .education .left-column .fa, .project .left-column .fa {
      font-size: 2em; }
    .experience .left-column .project-link, .education .left-column .project-link, .project .left-column .project-link {
      font-size: 1.5em; }
    .experience .left-column h4, .education .left-column h4, .project .left-column h4 {
      margin-bottom: 0.4em;
      font-weight: 500; }
      @media print {
        .experience .left-column h4, .education .left-column h4, .project .left-column h4 {
          margin-bottom: 0.2em; } }
      .experience .left-column h4 a, .education .left-column h4 a, .project .left-column h4 a {
        font-weight: 500; }

.project:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  box-shadow: inset 0 0 0 3px #533d4a; }

.footer-container {
  margin-top: 100px; }

.footer-container p {
  text-align: center;
  font-style: italic;
  font-weight: 300; }

.footer-container a {
  color: inherit;
  font-style: italic;
  font-weight: 300; }

.page-content {
  padding: 0; }

.contact a {
  line-height: 50px;
  margin-left: 30px;
  color: #BBBBBB;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease; }

.contact a:hover {
  color: #4C4C4C; }

p.quote {
  font-size: 1.4em;
  font-style: italic;
  padding: 1em 2.5em;
  text-align: center; }
  @media print {
    p.quote {
      text-align: left;
      padding: 0em 1em;
      margin-top: 1em; } }

.cursor-pointer {
  cursor: pointer; }

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
  color: whitesmoke;
  text-shadow: #533d4a 1px 1px, #533d4a 2px 2px, #533d4a 3px 3px, #533d4a 4px 4px, #533d4a 5px 5px, #533d4a 6px 6px; }

